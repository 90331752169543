import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [10,[2],[3]],
		"/(app)/cody/chat": [25,[2],[3]],
		"/(app)/cody/dashboard": [26,[2],[3]],
		"/embed/[...repo=reporev]/-/blob/[...path]": [28,[9]],
		"/(app)/search": [27,[2],[3]],
		"/(app)/[...repo=reporev]/(validrev)/(code)/-/blob/[...path]": [12,[2,4,6,7],[3,5]],
		"/(app)/[...repo=reporev]/-/branches": [16,[2,4,8],[3,5]],
		"/(app)/[...repo=reporev]/-/branches/all": [17,[2,4,8],[3,5]],
		"/(app)/[...repo=reporev]/(validrev)/-/changelists/[...path]": [14,[2,4,6],[3,5]],
		"/(app)/[...repo=reporev]/-/changelist/[changelistID]": [18,[2,4],[3,5]],
		"/(app)/[...repo=reporev]/(validrev)/-/commits/[...path]": [15,[2,4,6],[3,5]],
		"/(app)/[...repo=reporev]/-/commit/[...revspec]": [19,[2,4],[3,5]],
		"/(app)/[...repo=reporev]/-/compare/[...rangeSpec]": [20,[2,4],[3,5]],
		"/(app)/[...repo=reporev]/-/labels": [21,[2,4],[3,5]],
		"/(app)/[...repo=reporev]/-/stats/contributors": [22,[2,4],[3,5]],
		"/(app)/[...repo=reporev]/-/tags": [23,[2,4],[3,5]],
		"/(app)/[...repo=reporev]/(validrev)/(code)/-/tree/[...path]": [13,[2,4,6,7],[3,5]],
		"/(app)/[community=communitySearchContext]": [24,[2],[3]],
		"/(app)/[...repo=reporev]/(validrev)/(code)": [11,[2,4,6,7],[3,5]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';