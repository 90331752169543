import { handleErrorWithSentry } from '@sentry/sveltekit'
import * as Sentry from '@sentry/sveltekit'

Sentry.init({
    // Disabled if dsn is undefined
    dsn: window.context.sentryDSN ?? undefined,
    release: window.context.version,
    initialScope: {
        user: window.context.currentUser
            ? {
                  id: window.context.currentUser.databaseID,
                  graphqlID: window.context.currentUser.id,
                  username: window.context.currentUser.username,
                  displayName: window.context.currentUser.displayName,
                  email: window.context.currentUser.emails.find(email => email.isPrimary)?.email,
              }
            : undefined,
        tags: {
            app: 'sveltekit',
            siteID: window.context.siteID,
            externalURL: window.context.externalURL,
        },
    },
    ignoreErrors: [
        // We use a lot of lazy data fetching in repository pages. That also means that
        // we get a lot of 'uncaught (in promise)' errors if a non-existing repository is
        // accessed, since the data fetching is started but the promises are never "used"
        // (an error page is shown instead).
        // We don't want to log these errors in Sentry since those are not problems.
        // We still investigate how to avoid these uncaught promise errors in particular
        // in SRCH-958.
        /^RepoNotFoundError/,
    ],
    tracesSampleRate: 1.0,
    integrations: [
        Sentry.thirdPartyErrorFilterIntegration({
            filterKeys: ['sg-sk-webapp'],
            behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
        }),
    ],
})

export const handleError = handleErrorWithSentry()
